
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "StickyNote",
  props: {
    sourceData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, context) {
    const newData = computed(() => {
      return props.sourceData;
    });

    const handleUpdate = () => {
      newData.value.edit = !newData.value.edit;
      // 保存时修改时间
      if (!newData.value.edit) {
        newData.value.time = new Date().toLocaleString().replace(/\//g, "-");
      }
      context.emit("update-item", newData.value, 2);
    };
    const handleRemove = () => {
      context.emit("update-item", newData.value, 0);
    };
    // 完成|未完成
    const handleStatus = () => {
      newData.value.status = newData.value.status == 0 ? 1 : 0;
      context.emit("update-item", newData.value, 3);
    };

    // ctrl + enter 换行 enter 保存
    const onKeyUpEnter = (event: KeyboardEvent):any => {
      if (event) {
        if (event.ctrlKey || event.metaKey) {
          newData.value.content += "\n";
        } else if (event.key === "Enter") {
          handleUpdate();
        }
      }
    };

    return {
      newData,
      handleUpdate,
      handleRemove,
      handleStatus,
      onKeyUpEnter,
    };
  },
});
