
import { defineComponent, ref, reactive, computed } from "vue";
import StickyNote from "./components/StickyNote.vue";
export default defineComponent({
  name: "Todo",
  components: {
    StickyNote,
  },
  setup(props, context) {
    interface StickyNote {
      id: number;
      content: string;
      time: string;
      status: number;
      edit: boolean;
    }
    // 判断localStorage中是否有数据
    const localStorageData = localStorage.getItem("stickyNoteList");
    const stickyNoteList = reactive(
      localStorageData
        ? JSON.parse(localStorageData)
        : [
            {
              id: 1,
              content: "欢迎使用TodoList",
              time: "2023-05-05 12:00:00",
              status: 0,
              edit: false,
            },
            {
              id: 2,
              content: "这是个已经完成的任务",
              time: "2023-05-05 12:00:00",
              status: 1,
              edit: false,
            },
          ]
    );
    // 使用计算属性区分完成和未完成
    const doneList = computed(() => {
      // 需要按照时间排序 从大到小
      const arr = stickyNoteList.filter(
        (item: StickyNote) => item.status === 1
      );
      arr.sort((a: StickyNote, b: StickyNote) => {
        return new Date(b.time).getTime() - new Date(a.time).getTime();
      });
      return arr;
    });
    const undoneList = computed(() => {
      // 需要按照时间排序 从大到小
      const arr = stickyNoteList.filter(
        (item: StickyNote) => item.status === 0
      );
      arr.sort((a: StickyNote, b: StickyNote) => {
        return new Date(b.time).getTime() - new Date(a.time).getTime();
      });
      return arr;
    });
    // 折叠
    const handleFold = (elemName: string) => {
      const elem = document.querySelector(`.${elemName}`) as HTMLElement;
      if (!elem) {
        return;
      }
      const style = window.getComputedStyle(elem);
      elem.style.flex = style.flex === "1 1 0%" ? "0" : "1";

      const otherElemName =
        elemName === "todo-undone" ? "todo-done" : "todo-undone";
      const otherElem = document.querySelector(
        `.${otherElemName}`
      ) as HTMLElement;
      if (otherElem) {
        otherElem.style.flex = "1";
      }
    };
    // updateItem
    const updateItem = (item: StickyNote, noteType: number) => {
      const index = stickyNoteList.findIndex(
        (note: StickyNote) => item.id === note.id
      );
      // 删除
      if (noteType == 0) {
        stickyNoteList.splice(index, 1);
      }
      // 新增
      if (noteType == 1) {
        stickyNoteList.unshift(item);
      }
      // 更新
      if (noteType == 2) {
        stickyNoteList.splice(index, 1, item);
      }
      // 更改状态
      if (noteType == 3) {
        // 如果是编辑状态，就把编辑状态改为false
        if (item.edit) {
          item.edit = false;
        }
        stickyNoteList[index].status = item.status;
      }
      // 把数组存入localStorage
      localStorage.setItem("stickyNoteList", JSON.stringify(stickyNoteList));
    };
    // 新增
    const handleAddUndone = () => {
      const item = {
        id: new Date().getTime(),
        content: "",
        time: "",
        status: 0,
        edit: true,
      };
      updateItem(item, 1);
    };
    return {
      stickyNoteList,
      doneList,
      undoneList,
      handleFold,
      handleAddUndone,
      updateItem
    };
  },
});
